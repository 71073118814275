import React from 'react'
import { Layout } from '~/src/components'
import { Header } from 'planetyze-ui'
import { Menu } from './index'

const LayoutContainer = (props) => {
  const { title, header, url, children } = props
  return (
    <Layout title={title}>
      <div className='max-w-lg mx-auto'>
        <Header title={header} className='text-center' />
        <Menu current={url} />
        {children}
      </div>
    </Layout>
  )
}

export default LayoutContainer
