import React from 'react'
import { Layout } from '../components'
import { Formik } from 'formik'
import { Form } from './components'
import { FormikErrors } from 'libs/errors'
import * as Yup from 'yup'
import { graphql } from 'react-apollo'
import USER_UPDATE_MUTATION from './Password.gql'
import getContext from 'context'

const Password = (props) => {
  const { t } = getContext()
  const { changePassword } = props

  const onSubmit = (variables, actions) => {
    actions.setStatus(false)
    changePassword({
      variables
    })
      .then((res) => {
        actions.setSubmitting(false)
        actions.setStatus(true)
      })
      .catch((errors) => FormikErrors(errors, actions))
  }

  const initialValues = {
    password: '',
    newPassword: ''
  }

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(),
    newPassword: Yup.string().required()
  })

  return (
    <Layout
      title={t('auth.password.title')}
      header={t('auth.password.title')}
      url='/auth/password'>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        component={Form}
      />
    </Layout>
  )
}

export default graphql(USER_UPDATE_MUTATION, { name: 'changePassword' })(
  Password
)
