import React from 'react'
import { Input, Button } from 'planetyze-ui'
import getContext from 'context'
// import { Link } from 'libs/router'

const Form = (props) => {
  const { t } = getContext()

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting
  } = props

  return (
    <div className='w-full max-w-sm mx-auto'>
      <form className='px-4 mt-6 mb-8'>
        <Input
          type='email'
          name='email'
          placeholder={t('fields.email')}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.email}
          value={values.email}
        />

        <Input
          type='password'
          name='password'
          placeholder={t('fields.password')}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.password}
          value={values.password}
        />

        {/* <Link to='/recover' className='mb-8 block hover:underline'>
              {t('login.forgot-password')}
            </Link> */}

        <Button
          type='submit'
          className='w-full'
          size='lg'
          theme='blue'
          disabled={isSubmitting}
          isSubmitting={isSubmitting}
          onClick={handleSubmit}>
          {t('buttons.login')}
        </Button>
        {errors.default && (
          <p className='text-red text-xs italic mt-4'>{errors.default}</p>
        )}
        {/* <div className='mt-6'>
              {t('login.have-account')}
              <Link to='/signup' className='hover:underline ml-2'>
                {t('login.signup')}
              </Link>
            </div> */}
      </form>
    </div>
  )
}

export default Form
