export const FormikErrors = (errors, actions) => {
  const error = errors.graphQLErrors[0]
  if ("code" in error && error.code === "fields") {
    actions.setErrors(error.validation)
  } else if (error.message === "validation") {
    let validation = {}
    error.validation.forEach((item, key) => {
      validation[key] = item[0]
    })

    actions.setErrors(validation)
  } else {
    actions.setErrors({ default: error.message })
  }
  actions.setSubmitting(false)
  actions.setStatus(false)
}
