import React from "react"
import { Layout } from "~/src/components"
import { Header } from "planetyze-ui"
import getContext from "context"
import { graphql } from "react-apollo"
import LOGIN_MUTATION from "./Login.gql"
import { Form } from "./components"
import { Formik } from "formik"
import { FormikErrors } from "libs/errors"
import * as Yup from "yup"

const Login = props => {
  const { t, login } = getContext()
  const { Authenticate } = props

  const onSubmit = (variables, actions) => {
    Authenticate({ variables })
      .then(res => {
        login(res.data.Authenticate)
        actions.setSubmitting(false)
      })
      .catch(errors => FormikErrors(errors, actions))
  }

  const initialValues = {
    email: "",
    password: ""
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(),
    password: Yup.string()
      .min(6)
      .required()
  })

  return (
    <Layout
      title={t("seo.login.title")}
      description={t("seo.login.description")}
      pageType="login"
      url={props.uri}>
      <div className="container mx-auto  max-w-lg">
        <Header
          title={t("login.title")}
          subtitle={t("login.description")}
          className="text-center"
          titleClass="text-5xl lg:text-6xl mb-4"
        />
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          component={Form}
        />
      </div>
    </Layout>
  )
}

export default graphql(LOGIN_MUTATION, { name: "Authenticate" })(Login)
