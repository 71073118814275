import React from 'react'
import { Input, FormGroup, Button } from 'planetyze-ui'
import getContext from 'context'

const Form = (props) => {
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    status,
    isSubmitting,
    setStatus
  } = props

  const { t } = getContext()

  return (
    <div className='w-full max-w-sm mx-auto'>
      {status && (
        <div className='bg-green-lightest text-green-dark mb-16 p-8 text-center'>
          <div className='text-lg' children={t('auth.profile.success')} />
          <div
            onClick={() => setStatus(false)}
            className='bg-white border py-4 px-2 text-black rounded mt-8 block mx-auto max-w-xs cursor-pointer hover:border-grey-dark'>
            {t('buttons.back')}
          </div>
        </div>
      )}

      {!status && (
        <form className='px-4 my-20'>
          <FormGroup title={t('fields.first-name')}>
            <Input
              name='first_name'
              placeholder={t('fields.first-name')}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.first_name}
              value={values.first_name}
            />
          </FormGroup>

          <FormGroup title={t('fields.last-name')}>
            <Input
              name='last_name'
              placeholder={t('fields.last-name')}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.last_name}
              value={values.last_name}
            />
          </FormGroup>

          <FormGroup title={t('fields.email')}>
            <Input
              type='email'
              name='email'
              placeholder={t('fields.email')}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.email}
              value={values.email}
            />
          </FormGroup>

          <Button
            type='submit'
            className='w-full'
            size='lg'
            theme='blue'
            disabled={isSubmitting}
            isSubmitting={isSubmitting}
            onClick={handleSubmit}>
            {t('buttons.save')}
          </Button>

          {errors.default && (
            <p className='text-red text-xs italic mt-4'>{errors.default}</p>
          )}
        </form>
      )}
    </div>
  )
}

export default Form
