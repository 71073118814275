import React from 'react'
import { Menu } from 'planetyze-ui'
import { AppConsumer } from '~/src/context/AppContext'

const AuthMenu = (props) => {
  return (
    <AppConsumer>
      {({ t }) => {
        const menu = [
          { label: t('auth.profile.title'), url: '/auth/profile' },
          { label: t('auth.password.title'), url: '/auth/password' },
          { label: t('auth.reviews.title'), url: '/auth/reviews' },
          { label: t('auth.wishlist.title'), url: '/auth/wishlist' }
        ]
        return <Menu items={menu} {...props} className='flex border-b pb-4' />
      }}
    </AppConsumer>
  )
}

export default AuthMenu
